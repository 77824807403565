/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  TrackingServiceV3,
  DataLayerComponent,
  TrackingEvent,
} from '@volkswagen-onehub/oneaudi-os-onesight';
import { Logger } from '@feature-hub/core';
import { TrackingHelper } from './TrackingHelper';

export class TrackingV3Helper implements TrackingHelper {
  private trackingService: TrackingServiceV3;

  private loggerService: Logger;

  constructor(trackingService: TrackingServiceV3, loggerService: Logger) {
    this.trackingService = trackingService;
    this.loggerService = loggerService;
  }

  getTrackingService(): TrackingServiceV3 {
    return this.trackingService;
  }

  sendReadyEvent = () => {
    const payload: TrackingEvent = {
      attributes: {
        componentName: 'feature-gallery',
      },
      eventInfo: {
        eventAction: 'feature_app_ready',
        eventName: 'feature gallery - feature app ready',
      },
    };

    this.trackingService.track(payload);
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendReadyEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendClickEvent = (component: string) => {
    const payload: TrackingEvent = {
      attributes: {
        componentName: this.trackingService.featureAppName,
        clickID: '',
        elementName: 'arrow',
        value: 'pagination bar',
        label: component,
        targetURL: '',
        actionType: 'click',
      },
      eventInfo: {
        eventAction: 'image',
        eventName: `${this.trackingService.featureAppId} - change image`,
      },
    };

    this.trackingService.track(payload);
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendClickEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendExitLinkEvent = (component: string, link: string) => {
    const payload: TrackingEvent = {
      attributes: {
        clickID: '',
        componentName: this.trackingService.featureAppName,
        elementName: 'text link',
        targetURL: link,
        label: component,
        actionType: 'click',
      },
      eventInfo: {
        eventAction: 'exit_link',
        eventName: `${this.trackingService.featureAppName} - click on link`,
      },
    };

    this.trackingService.track(payload);
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendExitLinkEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };
  // eslint-disable-next-line
  trackImpressionEvent = (ref: React.MutableRefObject<any>, updateCallback: Function) => {
    const createPayload = (): TrackingEvent => {
      return {
        attributes: {
          componentName: 'feature gallery',
          elementName: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'impression',
          eventName: 'feature gallery - impression',
        },
      };
    };

    const createCallback = (payload: TrackingEvent): (() => TrackingEvent) => {
      // needs to wrapped in timeout to make it work in the "diff component", so that it is
      // triggered AFTER the lib executed the returned payload in the track() call
      return () => {
        setTimeout(() => {
          // eslint-disable-next-line
          let digitalData: any;
          if (typeof window !== 'undefined') {
            digitalData = window.digitalData;
          }
          const eventCount = digitalData?.event?.length;
          this.loggerService.info(
            `[Legacy Tracking V3 :: trackImpressionEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
            payload,
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          updateCallback();
        }, 1000);

        return payload;
      };
    };

    const defaultPayload = createPayload();
    const customPayload = createPayload();

    this.trackingService.registerImpressionTracking(ref.current, createCallback(defaultPayload), {
      delay: 10000,
      createImpressionEvent: createCallback(customPayload),
    });
  };

  updateComponent = (teaser: string) => {
    const payload: DataLayerComponent = {
      category: {
        primaryCategory: 'accx',
      },
      // eslint-disable-next-line
      // @ts-ignore
      attributes: {
        teaserTitle: teaser,
      },
    };

    this.trackingService.updateComponent(payload);
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: updateComponent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };
}
